import React from "react"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const DescriptionSection = ({ data }) => {
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  const imgs = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "desc-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "desc-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className="left-side">
        <h2>{data.title}</h2>
        {documentToReactComponents(data.text.json, options)}
      </div>
      <div className="right-side">
        <Img fluid={imgs.img1.childImageSharp.fluid} className="img1" />
        <Img fluid={imgs.img2.childImageSharp.fluid} className="img2" />
        <div className="leaf" />
      </div>
    </Container>
  )
}

export default DescriptionSection

const Container = styled.div`
  max-width: 1480px;
  margin: 0em auto 4em auto;
  padding: 2.5em 7em 2em 7em;
  position: relative;
  display: flex;
  h2 {
    line-height: 1.4em;
    margin-top: 0.35em;
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
    font-weight: 400;
  }
  p {
    font-size: 16px;
    line-height: 1.6em;
    margin: 1.5em 0;
  }
  .left-side {
    min-width: 450px;
    max-width: 450px;
  }
  .right-side {
    position: relative;
    width: 100%;
  }
  .img1 {
    width: 320px;
    height: 215px;
    position: absolute !important;
    right: 0;
    top: 1.5em;
  }
  .img2 {
    width: 440px;
    height: 290px;
    position: absolute !important;
    right: 15em;
    top: 12em;
  }
  .leaf {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 5em;
    right: 32.5em;
    background-image: url(${require("../images/leaf.svg")});
  }
  @media only screen and (max-width: 1375px) {
    flex-direction: column;
    padding: 2.5em 7em 30em 7em;
    .left-side {
      max-width: unset;
    }
  }
  @media only screen and (max-width: 840px) {
    padding: 2.5em 10px 30em 10px;
    h2 {
      font-size: 28px !important;
      line-height: 1.2em;
    }
  }
  @media only screen and (max-width: 730px) {
    .left-side {
      min-width: unset;
    }
    .img1 {
      width: 50%;
    }
    .img2 {
      width: 65%;
      right: 35%;
    }
    .leaf {
      width: 120px;
      height: 120px;
      background-position: center;
      background-size: cover;
      top: 7.5em;
      right: 77%;
    }
  }
  @media only screen and (max-width: 500px) {
    padding: 2.5em 10px 18em 10px;
    .left-side {
      min-width: unset;
    }
    .img1 {
      width: 170px;
      height: 120px;
    }
    .img2 {
      width: 260px;
      height: 180px;
      top: 7.5em;
      right: 6em;
    }
    .leaf {
      width: 120px;
      height: 120px;
      background-position: center;
      background-size: cover;
      top: 2em;
      right: 67%;
    }
  }
`
