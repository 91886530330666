import React from "react"
import styled from "styled-components"
import PremiumCard from "./PremiumCard"

const Container = styled.div`
  max-width: 1500px;
  margin: auto;
  padding-left: ${props => (props.page !== "cat" ? "1em" : "")};
  position: relative;
  transform: ${props => (props.page !== "cat" ? "translateY(-6.25em)" : "")};
  .wrapper {
    padding: 5em 0.75em 3em 0.75em;
    padding-top: ${props => (props.page !== "cat" ? "5em" : "2em")};
    background: #f4f4f4;
  }
  h2 {
    font-weight: 400;
    font-size: 40px;
    line-height: 1.1em;
    font-family: "Vidaloka", serif;
    margin-bottom: 0.35em;
    margin-top: 0.25em;
    margin-right: 2em;
    color: #1d2b3f;
    letter-spacing: 1px;
    max-width: 600px;
    width: 100%;
  }
  p {
    line-height: 1.5em;
    color: #454545;
    font-size: 15px;
    max-width: 700px;
  }
  .text {
    display: flex;
    align-items: center;
    padding-left: 7px;
  }
  .cards {
    margin: 1.25em 0em 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    grid-gap: 0.8em;
    position: relative;
  }
  .special-offer {
    position: absolute;
    top: 1.2em;
    left: 7px;
    background: #feede2;
    z-index: 2;
    font-weight: 700;
    padding: 7px 10px;
  }
  @media only screen and (max-width: 1270px) {
    padding-left: 0em;
  }
  @media only screen and (max-width: 980px) {
    .text {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media only screen and (max-width: 840px) {
    transform: translateY(0);
    .wrapper {
      padding: 2em 0.75em 2.5em 0.75em;
    }
    h2 {
      font-size: 26px;
    }
    .cards {
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    }
    .special-offer {
      left: 0;
    }
  }
`

const PremiumSection = ({ data, title, page }) => {
  return (
    <Container page={page}>
      <div className="wrapper">
        <div className="text">
          <h2>Featured {title ? title : "Massages"} Bristol</h2>
          <p>
            A Top Selection From The Best Practitioners & Therapists In Bristol.
            We hand select these massage providers to make sure you are getting
            the best and most relaxing deal in Bristol.
          </p>
        </div>
        <div className="cards">
          <span className="special-offer"></span>
          {data.edges.map(({ node }) => (
            <PremiumCard client={node} key={node.name} />
          ))}
        </div>
      </div>
    </Container>
  )
}

export default PremiumSection
