import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ServiceList from "./Selects/ServiceList"
import { FaMapMarkerAlt, FaStar } from "react-icons/fa"

const ServicesCard = ({ client }) => {
  return (
    <Container className={client.premiumPractitioner ? "premium" : ""}>
      {client.premiumPractitioner ? (
        <span className="card-tag premium">
          <FaStar size={11} style={{ fill: "yellow" }} /> Featured Practitioner
        </span>
      ) : null}
      <div className="flex-box">
        <div className="card-image">
          {client.logo ? (
            <Img
              fluid={client.mainImage.fluid}
              style={{ height: "300px" }}
              className="card-logo"
            />
          ) : null}
        </div>
        <div className="card-info-content">
          <p className="card-name">{client.name}</p>
          <div className="location">
            <svg
              width="13"
              height="13"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
            </svg>
            <span>
              {client.distanceToUser
                ? client.distanceToUser.toFixed(2) + " mi "
                : null}
              {client.address ? client.address : "Bristol, UK"}
            </span>
          </div>
          <div className="services">
            {client.services ? (
              <ServiceList client={client} />
            ) : (
              <div className="no-services label">
                No services to be displayed.
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ServicesCard

const Container = styled.div`
  width: 70%;
  position: relative;
  margin: auto;
  margin-top: 2em;
  width: 8em
  background: transparent;
  min-height: 290px;
  color: #1d2b3f;
  box-shadow: 0 0 21px 0 #eee;
  .card-tag {
      position: absolute;
  }
  .flex-box {
      display: flex;
      flex-direction: row;
      .card-image {
          width: 30%;
          img {
              height: 290px !important;
          }
      }
      .card-info-content {
          width: 70%;
          .card-name {
              font-size: 1.75em;
              padding: .5em;
          }
          .location {
              padding: 0 0 0 1em;
              color:  #909090;
              svg {
                  margin-right:.2em;
              }
          }
          .services {
              margin-top:.5em;

          }
          .label {
              text-align: center;
              width: 100%;
              padding: 1em 0;
              color: #909090;
          }
      }
  }
  @media only screen and (max-width: 500px) {
    width: 90%;
    height: unset;
    margin: 1em auto;
    .flex-box {
        flex-direction: column;
        .card-image {
            height: 250px;
            width: 100%;
            img {
                height: 250px !important;
            }
        }
        .card-info-content {
            width: 100%;
    }

`
