import React, { useState } from "react"
import styled from "styled-components"

const ServiceList = ({ client }) => {
  const [serviceLimit, setLimit] = useState(4)

  const addToLimit = e => {
    setLimit(serviceLimit + 4)
  }
  return (
    <Container>
      {client.services.slice(0, serviceLimit).map(service => (
        <div className="service">
          <span className="service-name">{service.massageTypes.name}</span>
          <span className="service-price">£{service.price}</span>
          <span className="service-time">{service.time} mins</span>
          <span className="service-link">
            <a href={service.link}>Book</a>
          </span>
        </div>
      ))}
      <a
        className="more-services label"
        style={
          client.services
            ? serviceLimit >= client.services.length
              ? { display: "none" }
              : {}
            : { display: "none" }
        }
        onClick={addToLimit}
      >
        + see more services
      </a>
    </Container>
  )
}

export default ServiceList

const Container = styled.div`
  .service:first-child {
    border-top: 1px solid #eee;
  }
  .service {
    position: relative;
    height: 50px;
    border-bottom: 1px solid #eee;
    color: #1d2b3f;
    .service-name {
      padding: 1em;
      left:0;
      position: absolute;
    }
    .service-time,
    .service-price {
      position: absolute;
      right: 7em;
      font-size: 0.75em;
      color: grey;
    }
    .service-price {
      top: 0.7em;
      color: #1d2b3f;
      font-weight: bold;
    }
    .service-time {
      bottom: 1em;
    }
    .service-link {
      position: absolute;
      font-size: 0.8em;
      top: 0.5em;
      right: 1em;
      border-radius: 5px;
      color: #feede2;
      background-color: #1d2b3f;
      padding: 8px 10px;
      text-transform: uppercase;
    }
  }
  .more-services {
    display: block;
    cursor: pointer;
    padding: 1em;
  }
`
