import React from "react"
import styled from "styled-components"
import ReviewCard from "./ReviewCard"

const ReviewsSection = ({ data }) => {
  return (
    <Container>
      <h2>Our Recent Reviews</h2>
      {data.edges.map((review, index) => (
        <ReviewCard review={review.node} key={index} />
      ))}
    </Container>
  )
}

export default ReviewsSection

const Container = styled.div`
  max-width: 1480px;
  margin: auto;
  h2 {
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
    font-weight: 400;
    color: #1d2b3f;
    margin-bottom: 0.7em;
    padding-left: 5px;
  }
  @media only screen and (max-width: 1100px) {
    display: none;
  }
`
