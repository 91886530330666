import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { FaMapMarkerAlt, FaStar } from "react-icons/fa"

const FeaturedCard = ({ client, bg, selected }) => {
  return (
    <Container bg={bg} className={client.premiumPractitioner ? "premium" : ""}>
      {client.premiumPractitioner ? (
        <span className="card-tag premium">
          <FaStar size={11} style={{ fill: "yellow" }} /> Featured Practitioner
        </span>
      ) : null}
      {client.distanceToUser ? (
        <span className="card-tag distance">
          <FaMapMarkerAlt size={13} />{" "}
          {client.distanceToUser.toFixed(2) + " Miles away"}
        </span>
      ) : null}
      <div
        className="card-bg"
        style={{
          backgroundImage: `url(${require(`../images/pattern-${bg}.svg`)})`,
        }}
      />
      {client.logo ? (
        <Img fluid={client.logo.fluid} className="card-logo" />
      ) : null}
      <div className="card-info-content">
        <p className="card-name">{client.name}</p>
        <div className="tags-contanier">
          {client.massageTypes.map(tag => (
            <span
              className={`${tag.shortName}-outline tag-btn`}
              key={tag.shortName}
            >
              {tag.shortName}
            </span>
          ))}
        </div>
        <div className="location">
          <svg
            width="13"
            height="13"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
          </svg>
          <span>{client.address ? client.address : "Bristol, UK"}</span>
        </div>
      </div>
      <button className="book-btn">
        <a rel="noopener noreferrer" target="_blank" href={`${client.website}`}>
          View Massages
        </a>
      </button>
    </Container>
  )
}

export default FeaturedCard

const Container = styled.div`
  width: 100%;
  background: transparent;
  height: 100%;
  position: relative;
  border-radius: 10px;
  width: 300px;
  min-height: 340px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.11);
  padding-bottom: 3em;
  &.premium {
    box-shadow: 0 0 11px 0 darkgreen;
  }
  .card-bg {
    width: 100%;
    height: 85px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-position: center;
    background-size: cover;
    background-color: ${props =>
      props.bg === 0 || props.bg === 3 || props.bg === 4 || props.bg === 6
        ? "#FEEDE2"
        : "#1D2B3F"};
  }
  .card-logo {
    position: absolute !important;
    width: 80px;
    height: 80px;
    top: 1.75em;
    right: 1em;
    object-fit: cover;
  }
  .card-info-content {
    padding: 10px 10px 2em 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    white-space: break-spaces;
  }
  .card-name {
    color: #454545;
    font-family: "Vidaloka";
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
    padding-left: 5px;
    margin-top: 0.5em;
  }
  .location {
    font-size: 14px;
    color: #454545;
    text-transform: capitalize;
    line-height: 1.6em;
    margin: 0;
    display: flex;
    span {
      padding-left: 0.25em;
    }
    svg {
      fill: #454545;
      position: relative;
      transform: translateY(4px);
      width: 20px;
    }
  }
  a {
    font-weight: 700;
  }
  .tag-btn {
    display: inline-flex;
    align-items: center;
    text-align: center;
    list-style: none;
    margin: 0.25em 0.25rem;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 2px;
    color: #454545 !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 4px 7px;
    font-size: 10px;
  }
  .tags-contanier {
    margin: 0.5em 0 1em 0;
  }
  .card-tag {
    position: absolute;
    z-index: 2;
    font-weight: 700;
    padding: 7px 10px;
    left: 0;
    font-size: 14px;
    line-height: 0;
    border-radius: 0px 10px 10px 0;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #000;
    background: #feede2;
    opacity: 0.95;
  }
  .distance {
    top: 3em;
    z-index: 2;
    font-weight: 700;
    padding: 7px 10px;
  }
  .premium {
    top: 0.2em;
    background: darkgrey;
    color: yellow;
  }

  .book-btn {
    margin-top: 1.5em;
    margin-left: 1em;
    margin-bottom: 1.25em;
    position: absolute;
    bottom: 0;
    width: fit-content;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 100px;
    background: #feede2;
    transition: all 0.5s;
    cursor: pointer;
    color: #1d2b3f;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 14px 12px;
    &:hover {
      filter: brightness(104%);
    }
    a {
      font-weight: 700;
      padding: 4px 10px;
      padding: 14px 12px;
    }
  }
  @media only screen and (max-width: 840px) {
    width: 100%;
    margin: 0.5em 0;
    min-height: 350px;
    .card-name {
      font-size: 20px;
    }
    .location {
      font-size: 12px;
    }
    .scroll-menu-arrow {
      padding: 25px;
    }
  }
`
