import React, { useState } from "react"
import FeaturedCard from "./FeaturedCard"

// One item component
// selected prop will be passed
const MenuItem = ({ node, selected }) => {
  const [bgs, setBgs] = useState([])

  const getBg = slug => {
    let existingBgs = bgs
    if (!bgs[slug]) {
      existingBgs[slug] = Math.floor(Math.random() * Math.floor(7))
      setBgs(existingBgs)
    }
    return bgs[slug]
  }
  return (
    <div className={`menu-item ${selected ? "active" : ""}`}>
      <FeaturedCard
        client={node}
        selected={false}
        bg={getBg(node) === "pattern-undefined" ? 1 : getBg(node.slug)}
      />
    </div>
  )
}

// All items component
// Important! add unique key
export const Menu = (list, selected) =>
  list.map(el => {
    return <MenuItem node={el} key={el.name} selected={selected} />
  })
