import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const PremiumCard = ({ client }) => {
  return (
    <Container>
      <div className="img-wrp">
        <Img fluid={client.mainImage.fluid} className="card-img" />
        <div className="top-info">
          <p className="card-name">{client.name}</p>
          <div className="tags-contanier">
            {client.massageTypes.map(tag => (
              <span
                className={`${tag.shortName}-outline tag-btn`}
                key={tag.shortName}
              >
                {tag.shortName}
              </span>
            ))}
          </div>
        </div>
      </div>
      {client.logo ? <Img fluid={client.logo.fluid} className="logo" /> : null}
      <div className="card-info-content">
        <div className="location">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
          </svg>
          <span>{client.address ? client.address : "Bristol, UK"}</span>
        </div>

        <button className="book-btn">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`${client.website}`}
          >
            View Massages
          </a>
        </button>
      </div>
    </Container>
  )
}

export default PremiumCard

const Container = styled.div`
  width: calc(100% - 1em);
  margin: 0.5em;
  background: transparent;
  height: 100%;
  position: relative;
  border-radius: 10px;
  max-width: 500px;
  .card-img {
    width: 100%;
    height: 300px;
  }
  .img-wrp {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(29, 43, 63, 0) 0%, #1d2b3f 100%);
    }
  }
  .logo {
    position: absolute !important;
    width: 150px;
    height: 150px;
    top: 0;
    right: 1.5em;
    object-fit: cover;
  }
  .top-info {
    position: absolute;
    bottom: 5px;
    z-index: 3;
    padding: 10px 2em;
  }
  .card-info-content {
    padding: 10px 20px;
    background: linear-gradient(180deg, #1d2b3f 0%, #1d2b3f 100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: calc(100% - 300px);
    padding-bottom: 4.5em;
  }
  .card-name {
    color: #ffffff;
    font-family: "Vidaloka";
    font-size: 30px;
    letter-spacing: 0;
    line-height: 36px;
  }
  .location {
    font-size: 20px;
    color: #fff;
    text-transform: capitalize;
    line-height: 1.6em;
    margin: 0;
    display: flex;
    span {
      padding-left: 0.25em;
    }
    svg {
      fill: #fff;
      position: relative;
      transform: translateY(8px);
      width: 25px;
    }
  }
  .book-btn {
    margin-top: 1.5em;
    margin-left: 0.5em;
    margin-bottom: 1.25em;
    position: absolute;
    bottom: 0;
    width: fit-content;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 100px;
    background: #feede2;
    transition: all 0.5s;
    cursor: pointer;
    color: #1d2b3f;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 14px 12px;
    &:hover {
      filter: brightness(105%);
    }
    a {
      font-weight: 700;
      padding: 4px 10px;
      padding: 14px 12px;
    }
  }

  .tag-btn {
    display: inline-flex;
    align-items: center;
    text-align: center;
    list-style: none;
    margin: 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    border-radius: 2px;
    color: #fff !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 4px 10px;
    font-size: 12px;
  }
  .tags-contanier {
    margin-top: 01em;
  }
  @media only screen and (max-width: 840px) {
    width: 100%;
    margin: 0.5em 0;
    .card-name {
      font-size: 24px;
    }
    .location {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 420px) {
    .logo {
      width: 120px;
      height: 120px;
    }
  }
`
