import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const ReviewCard = ({ review }) => {
  const [fill] = useState(new Array(review.rating).fill(0))
  const [outline] = useState(new Array(5 - review.rating).fill(0))
  return (
    <Container>
      <div className="left-side">
        <h3>{review.name}</h3>
        <div className="location">
          <svg
            width="13"
            height="13"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
          </svg>
          <span>{review.address ? review.address : "Bristol, UK"}</span>
        </div>
        <p>{review.description.description}</p>
        <div className="card-bottom">
          <span className="rating-title">Our Rating</span>
          {fill.map((item, index) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              key={index}
            >
              <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
            </svg>
          ))}
          {outline.map((item, index) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              key={index}
            >
              <path d="M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524-4.721 2.525.942-5.27-3.861-3.71 5.305-.733 2.335-4.817zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z" />
            </svg>
          ))}
          <button className="btn">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${review.website}`}
            >
              Find out more
            </a>
          </button>
        </div>
      </div>
      <div className="right-side">
        {review.image ? (
          <Img fluid={review.image.fluid} className="card-img" />
        ) : null}
        {review.logo ? (
          <Img fluid={review.logo.fluid} className="logo" />
        ) : null}
      </div>
    </Container>
  )
}

export default ReviewCard

const Container = styled.div`
  background: #feede2;
  border-radius: 10px;
  padding: 2.5em;
  display: flex;
  margin: 1em 0;
  position: relative;
  .left-side {
    margin-right: 3em;
  }
  h3 {
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .card-img {
    width: 400px;
    height: 250px;
    margin-top: 2em;
  }
  .logo {
    width: 150px;
    height: 150px;
    position: absolute !important;
    top: 0;
    right: 1.25em;
  }
  .location {
    margin: 1em 0;
    font-size: 20px;
    color: #1d2b3f;
    text-transform: capitalize;
    line-height: 1.6em;
    display: flex;
    span {
      padding-left: 0.25em;
    }
    svg {
      transform: translateY(9px);
    }
  }
  p {
    color: #454545;
    font-family: "Karla";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 1em;
    max-width: 1000px;
  }
  svg {
    fill: #1d2b3f;
  }
  .card-bottom {
    display: flex;
    align-items: center;
    svg {
      margin: 0 0.25em;
    }
  }
  .rating-title {
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 1em;
    font-weight: 400;
  }
  .btn {
    margin-top: 1.5em;
    margin-left: 4em;
    margin-bottom: 1.25em;
    bottom: 0;
    width: fit-content;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    border-radius: 100px;
    background: #1d2b3f;
    transition: all 0.5s;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 14px 12px;
    &:hover {
      filter: brightness(105%);
    }
    a {
      font-weight: 400;
      padding: 4px 10px;
      padding: 14px 12px;
    }
  }
  @media only screen and (max-width: 1100px) {
    padding: 2.5em 1.5em;
    .card-img {
      width: 340px;
      height: 200px;
    }
    .logo {
      width: 120px;
      height: 120px;
    }
    .left-side {
      margin-right: 1em;
    }
    .btn {
      margin-left: 2em;
    }
    .location {
      font-size: 18px;
    }
    .rating-title {
      font-size: 16px;
    }
    svg {
      margin: 0 0.2em !important;
    }
    h1 {
      font-size: 22px !important;
      margin-top: 0;
      line-height: inherit;
    }
  }
`
