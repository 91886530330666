import React, { useState } from "react"
import styled from "styled-components"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
const Text = ({ children }) => <p className="answer-1-content">{children}</p>

const Questions = ({ data }) => {
  const [question, setQuestion] = useState(0)

  return (
    <Contanier>
      <h2>Frequently Asked Questions</h2>
      {data.edges.map(({ node }, index) => {
        const Text = ({ children }) => (
          <p
            className="answer-content"
            style={index === question ? { height: "auto", opacity: "1" } : {}}
          >
            {children}
          </p>
        )

        const options = {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
          },
        }
        return (
          <div
            className="question-card"
            id={`question-card-${index}`}
            style={
              index === question
                ? { background: "#FEF6F0", border: "none" }
                : {}
            }
            key={index}
          >
            <div className="infos">
              <h4>{node.question}</h4>
              <div
                className="question-answer-content"
                id={`answer-${index}`}
                style={
                  index === question
                    ? { height: "auto", opacity: "1" }
                    : { overflow: "hidden", height: 0 }
                }
              >
                {node.answer1 &&
                  documentToReactComponents(node.answer1.json, options)}
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              id={`plus-${index}`}
              onClick={() => setQuestion(index)}
              style={index === question ? { display: "none" } : {}}
            >
              <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              className="minus"
              id={`minus-${index}`}
              onClick={() => setQuestion(-1)}
              style={index === question ? { display: "block" } : {}}
            >
              <path d="M0 10h24v4h-24z" />
            </svg>
          </div>
        )
      })}
    </Contanier>
  )
}

export default Questions

const Contanier = styled.div`
  max-width: 1480px;
  margin: auto;
  padding-bottom: 5em;
  h2 {
    line-height: 1.4em;
    padding-top: 0.5em;
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1em;
  }
  h4 {
    color: #1d2b3f;
    font-family: Karla;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.4em !important;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    color: #000000;
    font-family: Karla;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 1em;
    font-weight: 700;
    padding-right: 1em;
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s;
  }
  .minus {
    display: none;
  }
  .question-card {
    border: 2px solid #f3f4f5;
    padding: 2em;
    margin: 1.5em 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  svg {
    fill: #1d2b3f;
    min-width: 25px;
    cursor: pointer;
  }
  @media only screen and (max-width: 840px) {
    h2 {
      font-size: 28px !important;
      line-height: 1.2em;
    }
    h4 {
      font-size: 18px;
    }
  }
`
