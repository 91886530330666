import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
const Container = styled.div`
  max-width: 1480px;
  margin: 4em auto;
  padding: 2.5em 7em;
  background-image: url(${require("../images/outline-pattern.svg")});
  background-color: #feede2;
  h2 {
    line-height: 1.4em;
    margin-top: 0.35em;
    color: #1d2b3f;
    font-family: "Vidaloka", serif;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
    font-weight: 400;
  }
  p {
    font-weight: 500;
    margin-bottom: 2em;
  }
  .card-img {
    width: 35%;
    border-radius: 2px;
    flex-shrink: 0;
    flex-flow: row wrap;
  }
  .card {
    display: flex;
    border-radius: 10px;
    background: #fff;
    padding: 2em;
  }
  .cat-title {
    font-family: "Vidaloka", serif;
    color: #1d2b3f;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 15px;
    line-height: 1.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .grid-cards {
    margin: 2em 0em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 1.5em;
  }
  .cat-link {
    padding: 15px 30px;
    letter-spacing: 2px;
    transition: all 0.4s;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    border-radius: 100px;
    text-transform: uppercase;
    margin: 0.5em;
    color: #333;
    white-space: nowrap;
    &:hover {
      filter: brightness(105%);
    }
  }
  .card-info {
    padding-left: 5%;
    width: 60%;
  }
  @media only screen and (max-width: 840px) {
    padding: 2.5em 10px;
    h2 {
      font-size: 28px !important;
    }
  }
  @media only screen and (max-width: 570px) {
    .card-img {
      display: none;
    }
    .card-info {
      padding-left: 0em;
      width: 100%;
    }
    .grid-cards {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
`

const MassageTypes = ({ data }) => {
  return (
    <Container>
      <h2>Types of Massages</h2>
      <div className="grid-cards">
        {data.edges.map(({ node }) => (
          <div className={`${node.shortName}-outline card`} key={node.name}>
            <Img fluid={node.image.fluid} className="card-img" />
            <div className="card-info">
              <h5 className="cat-title">{node.name}</h5>
              <p>{node.description.description}</p>
              <Link
                to={"/" + node.slug}
                className={`${node.shortName} cat-link`}
              >
                All {node.name}s
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default MassageTypes
