import React, { useState, useRef } from "react"
import styled, { isStyledComponent } from "styled-components"
import ServicesCard from "./ServicesCard"
import ScrollMenu from "react-horizontal-scrolling-menu"
import { Menu } from "./FeaturedScrollMenu"

const Featured = ({ data, title, location, setLocation }) => {
  const [state, setState] = useState(data)
  const menuItems = Menu(state.nodes, "test")

  const scrollMenuRef = useRef()
  React.useEffect(() => {
    // if location already set then search and scroll search into view
    if (location !== "") {
      handleSubmit()
      document.querySelector(".services").scrollIntoView({
        behavior: "smooth",
      })
    }
  }, [])

  function getServiceNodes(nodes) {
    const returnedNodes = nodes.filter(function(node) { return node.serviceCards });
    console.log(returnedNodes)
    return returnedNodes;
  }
  // Distance between two points
  function getDistance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0
    } else {
      var radlat1 = (Math.PI * lat1) / 180
      var radlat2 = (Math.PI * lat2) / 180
      var theta = lon1 - lon2
      var radtheta = (Math.PI * theta) / 180
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      if (unit == "K") {
        dist = dist * 1.609344
      }
      if (unit == "N") {
        dist = dist * 0.8684
      }
      return dist
    }
  }

  function getLocationByPostcode() {
    // Return Lat and Long of postcode by Mapbox API Call
    return fetch("/api/mapbox?postcode=" + location).then(res => res.json())
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr // for testing
  }

  const addPremiumNodesToTop = state => {
    let premiumCardIndexes = []
    let newState = state
    state.nodes.forEach((node, i) => {
      if (node.premiumPractitioner) {
        premiumCardIndexes.push(i)
      }
    })
    premiumCardIndexes.forEach(oldIndex => {
      newState.nodes = array_move(newState.nodes, oldIndex, 0)
    })
    return newState
  }

  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }
    if (location) {
      getLocationByPostcode()
        .then(data => {
          let newState = {
            nodes: [],
          }
          const userlon = data[0]
          const userlat = data[1]
          const sort = state.nodes.forEach(node => {
            let nodeLon = node.location.lon
            let nodelat = node.location.lat
            let milageDistance = getDistance(userlat, userlon, nodelat, nodeLon)
            node["distanceToUser"] = milageDistance
            newState.nodes.push(node)
          })
          // Sort by distance to the user
          newState.nodes.sort(
            (a, b) =>
              parseFloat(a.distanceToUser) - parseFloat(b.distanceToUser)
          )
          newState = newState
          setState(addPremiumNodesToTop(newState))
          document.querySelector(".scroll-menu").scrollIntoView({
            behavior: "smooth",
          })
          scrollMenuRef.current.scrollTo(state.nodes[0].name)
        })
        .catch(error => {
          alert("ERROR when finding your location, please try again")
          setState(state)
        })
    }
  }

  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>
  }

  const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" })
  const ArrowRight = Arrow({ text: ">", className: "arrow-next" })

  return (
    <Container>
      <h2 id="search-title">Search for {title} in Bristol</h2>
      <div className="filters">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="location-inupt input-wrapper">
              <input
                type="text"
                name="location"
                placeholder="Enter Postcode"
                value={location}
                onChange={e => {
                  const val = e.target.value
                  setLocation(val)
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
              >
                <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"></path>
              </svg>
            </div>
            <input
              id="submit-location"
              type="submit"
              value="Search"
              className="submit-btn"
            />
          </div>
        </form>
      </div>
      <div className="scroll-menu">
        <ScrollMenu
          data={menuItems}
          ref={scrollMenuRef}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          inertiaScrolling={true}
          inertiaScrollingSlowdown={1.25}
        />
      </div>
      {
        <div className="services-cards">
          {getServiceNodes(state.nodes).map((node, index) => (
            <ServicesCard client={node} key={node.name} />
          ))}
        </div>
        /* <button
        className="more"
        style={limit >= state.nodes.length ? { display: "none" } : {}}
        onClick={handleLoadMore}
      >
        LOADS MORE
      </button> */
      }
    </Container>
  )
}

export default Featured

const Container = styled.div`
  max-width: 1480px;
  margin: auto;
  padding: 0em 10px 3em 10px;
  h2 {
    font-weight: 400;
    font-size: 40px;
    position: relative;
    transform: translateY(-0.8em);
    text-align: center;
    font-family: "Vidaloka", serif;
  }
  p {
    font-weight: 500;
  }
  .featured-cards {
    margin: 2.5em 0em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-gap: 1.5em;
  }
  .filters {
    margin: 0em 0em 2em 0;
    background: #feede2;
    padding: 20px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
    margin: 0 auto;
  }
  .input-wrapper {
    position: relative;
    svg {
      position: absolute;
      transform: translate(-30px, 23px);
      fill: #444;
      cursor: pointer;
    }
    &:hover {
      nav {
        width: 250px;
        transform: translateY(0px);
        height: 100%;
        opacity: 1;
      }
      .select-wrapper {
        z-index: 25;
      }
    }
  }
  .location-inupt {
    width: 70%;
    input {
      width: 100%;
      padding-left: 30px;
    }
    svg {
      position: absolute;
      bottom: -.72em;
      left: 1em;
      transform: translate(0, -37px);
    }
  }
  input {
    padding: 15px 20px;
    min-width: 250px;
    outline: none;
    font-size: 14px;
    color: #333;
    border: none;
    border-radius: 7px;
    margin: 7px 3px;
    font-weight: 700;
  }
  .submit-btn {
    width: 140px !important;
    min-width: unset;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
    background: #1d2b3f;
    transition: all 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    &:hover {
      filter: brightness(110%);
    }
    margin-left: 30px;
  }
  .select-input {
    cursor: pointer;
    color: transparent;
    text-shadow: 0 0 0 #ccc;
  }
  .more {
    background: transparent;
    font-weight: 700;
    border: 1px solid #1d2b3f;
    border-radius: 100px;
    padding: 12px 16px;
    letter-spacing: 2px;
    font-size: 14px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    outline: none;
  }
  @media only screen and (max-width: 1480px) {
    padding: 0em 0 3em 0;
    .featured-cards {
      padding: 0 10px;
    }
  }
  @media only screen and (max-width: 1150px) {
    .location-inupt {
      input {
        min-width: unset;
      }
    }
    .form-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  @media only screen and (max-width: 840px) {
    h2 {
      transform: translateY(0em);
      margin: 1em 0;
      font-size: 26px;
    }
  }
  @media only screen and (max-width: 650px) {
    form {
      flex-direction: column;
      align-items: center;
      .input-wrapper {
        width: 100% !important;
      }
    }
    .form-row {
      justify-content: center;
    }
    input[type="text"] {
      width: 100%;
    }
    .submit-btn {
      width: 100% !important;
      margin: 1.5em 0 0 0;
    }
  }

  .menu-item {
    padding: 0 50px;
    margin: 5px 40px;
    user-select: none;
    border: none;
    min-width: 200px;
    max-width: 300px;
    cursor: mouse;
  }
  .menu-item-wrapper {
    height: 100%;
  }
  
  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
    #1d2b3f
  }

  @media only screen and (max-width: 650px) {
    .menu-item {
      padding: 0 20px;
      margin: 0;
    }
    .scroll-menu-arrow {
      padding: 10px;
    }
  }
`
